<template>
  <v-app-bar class="pl-2 default-toolbar" app :color="!darkTheme ? 'primary' : '#363636'" fixed :dark="!darkTheme" clipped-left>
    <img class="logo mr-4" height="50" max-height="50" :src="require('@/assets/logo.png')" @click="goToHome" />
    <img class="logo" height="50" max-height="50" :src="require('@/assets/starlord.png')" @click="goToHome" />
    <v-spacer />
    <v-switch v-model="darkTheme" class="mr-12 mb-1 mt-6" color="error" value label="Modo Noturno"></v-switch>
    <div class="mr-4">
      <span v-if="name" class="ma-2">{{ name }}</span>
      <v-avatar v-if="name" color="red lighten" size="44" class="mx-2">
        <span class="white--text text-h3">{{ name.substring(0, 1) }}</span>
      </v-avatar>
      <v-icon class="ml-2" type="button" tag="a" style="text-decoration:none" @click="logout">mdi-logout</v-icon>
    </div>
  </v-app-bar>
</template>

<script>
import { formattedRedirectLogout } from '@/helpers/login'

export default {
  computed: {
    darkTheme: {
      get() {
        return localStorage.getItem('dark_theme') === 'true'
      },
      set(value) {
        localStorage.setItem('dark_theme', value)
        this.$router.go()
      }
    },
    name() {
      return this.$getLoggedUser()
        .name.split(' ')[0]
        .toUpperCase()
    }
  },
  methods: {
    goToHome() {
      this.$router.push('/').catch(() => {})
    },
    logout() {
      const singleLoginURL = this.$microservicesUrls['mfLogin']
      const host = location.href.slice(0, location.href.indexOf('#'))
      const hash = window.localStorage.getItem('redirectAfterLogin') || ''

      this.$logout()
      window.location.href = formattedRedirectLogout(singleLoginURL, host, hash)
    }
  }
}
</script>

<style lang="scss">
.default-toolbar {
  img {
    cursor: pointer;
    height: 50px !important;
  }
  z-index: 9 !important;
}
.z-index {
  z-index: 9 !important;
}
span {
  font-family: 'Lato', sans-serif;
}
</style>
